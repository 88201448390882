<template>
	<tr>
		<td  @click="() => item.imagePath ? $emit('click:image', item) : ''">
			<img v-if="item.imagePath" :src="item.imagePath" height="50" width="50">
		</td>
		<td>{{item.manufacturer}}</td>
		<td>{{item.productCategory}}</td>
		<td>{{item.sku}}</td>
		<td style="max-width: 280px">{{item.description}}</td>
		<td>{{item.shade}}</td>
		<td>{{item.serialNumber}}</td>
		<td style="width: 140px">
			<span class="was" v-if="item.listPrice">
				${{item.listPrice.toFixed(2)}}/{{item.unitOfMeasurement}}
				<br>
			</span>
			<span class="bold">${{item.salePrice.toFixed(2)}}/{{item.unitOfMeasurement}} (-{{item.percentOff}})</span>
		</td>
		<td>{{item.qtyAvailable.toLocaleString()}} {{item.unitOfMeasurement}}</td>
		<td style="width: 85px; padding-right: 5px">
			<input
				v-model.number="qty"
				style="width: 40px"
				:class="['qty-input', {'qty-error': qtyError}]"
				:disabled="itemAdded"
			>
			<span>{{item.unitOfMeasurement}}</span>
		</td>
		<td style="max-width: 155px" v-if="!itemAdded">
			<div class="blue-btn" @click="addToOrder">Add To Order</div>
		</td>
		<td style="max-width: 155px" v-else>
			<div class="added">
				<i class="fa fas fa-check"></i> Added
			</div>
		</td>
	</tr>
</template>

<script>
export default {
	name: 'AgedInventoryItemRow',
	props: {
		order: {
			type: Object,
			required: true
		},
		item: {
			type: Object,
			required: true
		}
	},
	computed: {
		itemAdded() {
			if(!this.order || !this.order.items) return false;
			return this.order.items.some(x => x.itemID == this.item.id);
		},
	},
	data() {
		return {
			qty: 0,
			qtyError: false
		}
	},
	methods: {
		addToOrder() {
			if(!this.qty || this.qty > this.item.qtyAvailable) {
				this.qtyError = true;
				return;
			}
			this.qtyError = false;
			this.$emit('click:add', {
				itemID: this.item.id,
				qty: this.qty,
				orderID: this.order.id
			});
		}
	}
}
</script>