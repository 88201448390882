<template>
	<div class="aged-inventory mbp-width" v-cloak>
		<div v-if="search">
			<div class="flex between align-center block-mobile">
				<div class="ai-copy">
					<h1>{{headline}}</h1>
					<p class="hide-mobile hide-tablet">{{copy}}</p>
				</div>
				<div
					class="ai-cart hide-tablet"
					@mouseenter="showOrder(true)"
					@mouseleave="showOrder(false)"
					v-if="!isTablet"
				>
					<div class="blue-med-btn order-btn">
						My Order <span class="hide-mobile">Request</span> <span v-if="itemCount > 0">({{itemCount}})</span>
					</div>
					<aged-inventory-order v-if="order" v-show="orderVisible && !orderModalVisible" />
					<br/><br/>
				</div>
				<div class="show-tablet ai-cart-mobile" @click="$store.commit('orderModalVisible', true)">
					<div class="blue-med-btn order-btn">
						My Order <span v-if="itemCount > 0">({{itemCount}})</span>
					</div>
				</div>
			</div>
			<div>
				<aged-inventory-filters v-if="search" />
				<aged-inventory-list v-if="search" />
			</div>
			<aged-inventory-order-modal
				v-if="order && orderModalVisible"
				@cancel="$store.commit('orderModalVisible', false)"
			/>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import AgedInventoryFilters from './AgedInventoryFilters.vue';
import AgedInventoryList from './AgedInventoryList.vue';
import AgedInventoryOrder from './AgedInventoryOrder.vue';
import AgedInventoryOrderModal from './AgedInventoryOrderModal.vue';

let orderTimer;

export default {
	name: 'AgedInventory',
	components: {
		AgedInventoryFilters,
		AgedInventoryList,
		AgedInventoryOrder,
		AgedInventoryOrderModal
	},
	computed: {
		itemCount() {
			let count = this.$store.getters['itemCount'];
			return count ? count : null;
		},
		isTablet() {
			return window.innerWidth <= 1100;
		},
		...mapState({
			copy: state => state.copy,
			error: state => state.error,
			headline: state => state.headline,
			order: state => state.order,
			search: state => state.search,
			showSpinner: state => state.showSpinner,
			orderModalVisible: state => state.orderModalVisible
		})
	},
	data() {
		return {
			orderVisible: false
		}
	},
	methods: {
		showOrder(show) {
			if(!this.order) return;
			if(show) {
				this.orderVisible = true;
				clearTimeout(orderTimer);
			} else {
				let self = this;
				orderTimer = setTimeout(() => {
					self.orderVisible = false;
				}, 150);
			}
		}
	},
	mounted() {
		this.$store.dispatch('getInitialData');
	}
}
</script>

<style lang="less">
	@import "~Less/_mixins.less";
	body.modal-open {
		overflow: hidden;
	}
	.aged-inventory {
		margin-top: 25px;
		position: relative;
	}
	.ai-cart {
		margin-left: 20px;
		flex: 0 0 240px;
		position: relative;
		.mobile-styles({
			margin: 0 0 20px;
		})
	}
	.ai-cart-mobile {
		margin-bottom: 20px;
	}
	.order-btn {
		width: 240px;
		padding: 10px;
	}
</style>