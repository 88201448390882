<template>
	<div class="spinner-wrapper" :style="{'background-color': overlayColor, 'position': fixed ? 'fixed' : 'absolute'}">
		<div class="spinner">
			<div class="spinner-icon">
				<i class="fas fa-cog fa fa-gear fa-spin"></i>
			</div>
			<div class="spinner-text">
				{{text}}
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Spinner',
	props: {
		text: {
			type: String,
			default: 'Please Wait'
		},
		overlayColor: {
			type: String,
			default: 'rgba(0,0,0,0.2)'
		},
		fixed: Boolean
	}
}
</script>


<style lang="less">
.spinner-wrapper {
	align-items: center;
	background-color: rgba(0,0,0,0.2);
	display: flex;
	justify-content: center;
	top: 0; right: 0; bottom: 0; left: 0;
	z-index: 10;
}
.spinner {
	background-color: #FFF;
	border-radius: 9px;
	box-shadow: 1px 1px 10px 0 rgba(0,0,0,0.3);
	padding: 30px;
	text-align: center;
	i.fas {
		color: #00539F;
		font-size: 60px;
		margin-bottom: 10px;
	}
}
</style>