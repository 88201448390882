<template>
	<div class="aged-inventory-list" ref="list">
		<div class="flex between align-center block-mobile" v-if="searchResult && searchResult.results">
			<b class="hide-mobile">{{totalText}}</b>
			<pagination
				:hide-total-text="true"
				:total-results="searchResult.totalResults"
				:results-per-page="searchRequest.resultsPerPage"
				@paginate="paginate"
				:page-num="searchRequest.pageNum"
			/>
		</div>
		<div v-if="searchResult && !resultsCount">
			<h2>Your search return 0 results</h2>
		</div>
		<table class="table table-responsive table-striped hide-tablet" v-if="resultsCount">
			<thead>
				<tr>
					<th>Image</th>
					<th>Manufacturer</th>
					<th>Category</th>
					<th>SKU</th>
					<th>Description</th>
					<th>Shade</th>
					<th>Serial #</th>
					<th>Price</th>
					<th>Qty Available</th>
					<th>Qty</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<aged-inventory-item-row
					v-for="i in searchResult.results"
					:key="i.id"
					:item="i"
					:order="order"
					@click:image="setActiveImage"
					@click:add="addToOrder"
				/>
			</tbody>
		</table>
		<div v-if="resultsCount" class="show-tablet">
			<aged-inventory-item
				v-for="i in searchResult.results"
				:key="i.id"
				:order="order"
				:item="i"
				@click:image="setActiveImage"
				@click:add="addToOrder"
			/>
		</div>
		<div class="flex between align-center" v-if="searchResult && searchResult.results">
			<b class="hide-mobile">{{totalText}}</b>
			<pagination
				:hide-total-text="true"
				:total-results="searchResult.totalResults"
				:results-per-page="searchResult.resultsPerPage"
				@paginate="paginate"
				:page-num="searchRequest.pageNum"
			/>
		</div>
		<div class="ai-img-modal flex center-all" v-if="!!activeImage">
			<div class="aii-inner">
				<div class="aii-close flex center-all" @click="activeImage = null">
					<i class="fa fas fa-times"></i>
				</div>
				<img :src="activeImage">
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import AgedInventoryItem from './AgedInventoryItem.vue';
import AgedInventoryItemRow from './AgedInventoryItemRow.vue';
import { Pagination } from '../../components/shared';
export default {
	name: 'AgedInventoryList',
	components: {
		AgedInventoryItem,
		AgedInventoryItemRow,
		Pagination
	},
	computed: {
		totalText() {
			if(!this.searchResult) return;
			let total = this.searchResult.totalResults;
			if(total == 0) return;
			let start = this.searchResult.pageNum > 1
				? (this.searchResult.pageNum - 1) * this.searchResult.resultsPerPage
				: 1;
			let end = this.searchResult.pageNum * this.searchResult.resultsPerPage;
			end = end > total ? total : end;
			return `Showing ${start} - ${end} of ${total} Items`;
		},
		resultsCount() {
			return this.$store.getters['resultsCount'];
		},
		...mapState({
			order: state => state.order,
			searchResult: state => state.search,
			searchRequest: state => state.searchRequest
		})
	},
	data() {
		return {
			activeImage: null
		}
	},
	methods: {
		addToOrder(item) {
			this.$store.dispatch('addItem', item);
		},

		paginate(pageNum) {
			this.$store.commit('searchRequest', {
				...this.searchRequest,
				pageNum
			});
			this.$store.dispatch('search');
			let top = this.$refs.list ? this.$refs.list.offsetTop : 0;
			document.body.scrollTop = document.documentElement.scrollTop = top;
		},

		itemAdded(item) {
			if(!this.order) return false;
			return this.order.items.some((x) => {
				if(x.itemID == item.id) {
					item.qty = x.qty;
					return true;
				}
			});
		},

		setActiveImage(item) {
			console.log(item);
			this.activeImage = item.imagePath;
		}

	}
}
</script>

<style lang="less">
.aged-inventory-list {
	flex: 1 1 auto;
	input.qty-error {
		border-color: red !important;
		outline: none !important;
	}
}
.aged-inventory-list table {
	thead tr th {
		font-weight: bold;
		vertical-align: top;
	}
	td,
	th {
		font-size: 13px;
	}
	.blue-btn {
		width: 150px;
	}
	img {
		cursor: pointer;
		max-width: none;
	}
}
.added {
	width: 150px;
	text-align: center;
	font-size: 14px;
	font-weight: bold;
	color: #00539f;
}
.was {
	text-decoration: line-through;
}
.ai-qty-modal,
.ai-img-modal {
	background: rgba(0, 0, 0, 0.3);
	padding: 15px;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 4;
}
.aii-inner {
	background-color: #fff;
	border-radius: 9px;
	max-width: 420px;
	padding: 15px;
	position: relative;
	width: 100%;
	img {
		height: auto;
		width: 100%;
	}
}
.aiq-inner {
	background-color: #fff;
	border-radius: 9px;
	max-width: 420px;
	padding: 25px;
	text-align: center;
	width: 100%;
	input {
		display: block;
		font-size: 18px;
		margin: 0 auto 20px;
		max-width: 100px;
	}
	.submit {
		> div {
			flex: 1 1 auto;
			margin: 0 0 0 6px;
			&:nth-child(1) {
				margin: 0 6px 0 0;
			}
		}
	}
}
.aii-close {
	background: #555;
	border-radius: 50%;
	border: solid 2px #fff;
	color: #fff;
	cursor: pointer;
	font-size: 18px;
	position: absolute;
	top: -12px;
	right: -12px;
	height: 30px;
	width: 30px;
}
</style>