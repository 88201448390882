import Vue from 'vue';
import Vuex from 'vuex';
import VueResource from 'vue-resource';
import AgedInventory from './components/AgedInventory.vue';
import store from './store';
import Spinner from '../components/shared/Spinner.vue';

Vue.use(VueResource);
Vue.use(Vuex);

const app = new Vue({
	el: "#closeouts",
	store,
	components: {
		AgedInventory,
		Spinner
	},
	computed: {
		showSpinner() {
			return this.$store.state.showSpinner;
		}
	}
});