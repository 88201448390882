import Vuex from 'vuex';
import VueResource from 'vue-resource';
import _get from 'lodash/get';

Vue.use(Vuex);
Vue.use(VueResource);

//#region Helpers

/*============================================================
 == Helpers
/============================================================*/

const BASE_URL = window.location.origin;

async function get(url, errCallback) {
	try {
		let response = await Vue.http.get(BASE_URL + url);
		if(response && response.ok && response.body) {
			return response.body;
		}
		return false;
	} catch(e) {
		let message = e.body || e.message || '';
		if(errCallback) errCallback(e);
		return false;
	}
}

async function post(url, data, errCallback) {
	try {
		let response = await Vue.http.post(BASE_URL + url, data);
		if(response && response.body) {
			return response.body;
		}
		return false;
	} catch(e) {
		let message = e.body || e.message || '';
		if(errCallback) errCallback(e);
		return false;
	}
}

//#endregion

/*============================================================
 == State
/============================================================*/

let state = {
	categories: [],
	copy: null,
	globalError: null,
	headline: null,
	manufacturers: [],
	order: null,
	orderModalVisible: false,
	search: null,
	searchRequest: {},
	showSpinner: true
};

/*============================================================
 == Mutations
/============================================================*/

let mutations = {

	categories(state, cats) {
		state.categories = cats;
	},

	copy(state, copy) {
		state.copy = copy;
	},

	globalError(state, err) {
		state.globalError = err;
	},

	headline(state, headline) {
		state.headline = headline
	},

	manufacturers(state, manufacturers) {
		state.manufacturers = manufacturers;
	},

	order(state, order) {
		state.order = order;
	},

	search(state, search) {
		if(!search) return;
		if(!search.category) search.category = "";
		if(!search.manufacturer) search.manufacturer = "";
		state.search = search;
	},

	searchRequest(state, searchRequest) {
		state.searchRequest = searchRequest;
	},

	showSpinner(state, show) {
		state.showSpinner = show;
	},

	orderModalVisible(state, show) {
		state.orderModalVisible = show;
	}

};

/*============================================================
 == Actions
/============================================================*/

let actions = {

	async addItem({commit}, item) {
		commit('showSpinner', true);
		let order = await post('/closeouts/additem', item);
		commit('showSpinner', false);
		if(!order) {
			commit('globalError', "Something went wrong, please try again. \r\n If the problem persists, please contact us at 1-800-236-7225");
			return false;
		}
		commit('order', order);
		return true;
	},

	async getInitialData({commit}) {
		commit('showSpinner', true);
		let data = await get('/closeouts/getinitialdata');
		commit('showSpinner', false);
		if(!data) {
			return false;
		}
		commit('categories', data.categories);
		commit('manufacturers', data.manufacturers);
		commit('search', data.search);
		commit('searchRequest', data.searchRequest);
		commit('order', data.order);
		commit('copy', data.copy);
		commit('headline', data.headline);
		return true;
	},

	async removeItem({commit}, item) {
		if(item.item) delete item.item;
		commit('showSpinner', true);
		let order = await post(`/closeouts/removeitem`, item);
		commit('showSpinner', false);
		if(!order) {
			commit('globalError', "Something went wrong, please try again. \r\n If the problem persists, please contact us at 1-800-236-7225");
			return false;
		}
		commit('order', order);
		return true;
	},

	async search({commit}) {
		commit('showSpinner', true);
		let newSearch = await post('/closeouts/searchitems', state.searchRequest);
		commit('showSpinner', false);
		if(!newSearch) {
			return false;
		}
		commit('search', newSearch);
	},

	async submitOrder({commit, state}) {
		commit('showSpinner', true);
		let order = await post('/closeouts/submitorder', state.order, function(err) {
			commit('showSpinner', false);
			if(err.status === 422) {
				commit('globalError', "We're sorry but the quantity you requested is no longer available. \r\n Please try again");
			}
		});
		if(!order) return false;
		commit('showSpinner', false);
		return true;
	}

}

/*============================================================
 == Getters
/============================================================*/

let getters = {

	itemCount(state) {
		return _get(state, 'order.items.length', 0);
	},

	itemCountText(state) {
		let x = _get(state, 'order.items.length', 0);
		if(x <= 0) return null;
		if(x === 1) return '(1 item)';
		return `(${x} items)`;
	},

	resultsCount(state) {
		return _get(state, 'search.totalResults', 0);
	}

}

/*============================================================
 == Store
/============================================================*/

export default new Vuex.Store({
	state,
	mutations,
	actions,
	getters
});