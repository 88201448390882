<template>
	<div class="pagination-wrapper" v-if="numPages > 1">
		<div class="pull-right" v-if="numPages > 1">
			<div v-if="!hideTotalText"><label>{{totalText}}</label></div>
			<div class="pages" v-if="pageText"><b>{{pageText}}</b></div>
			<ul class="pagination">
				<li v-on:click="paginate(currentPage - 1)">
					<a href="javascript:void(0);"><i class="fa fas fa-chevron-left"></i></a>
				</li>
				<li v-for="p in pages" v-bind:class="{'active': p == currentPage}" v-on:click="paginate(p)" :key="p">
					<a href="javascript:void(0);">{{p}}</a>
				</li>
				<li v-on:click="paginate(currentPage + 1)">
					<a href="javascript:void(0);"><i class="fa fas fa-chevron-right"></i></a>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Pagination',
	props: {
		pageNum: {
			type: Number,
			default: 1
		},
		totalResults: {
			type: Number,
			required: true
		},
		resultsPerPage: {
			type: Number,
			default: 25
		},
		hideTotalText: Boolean
	},
	computed: {
		pageText() {
			if(!this.currentPage || !this.totalPages) return;
			return `Page ${this.currentPage} of ${this.totalPages}`;
		},
		totalPages() {
			return Math.ceil(this.totalResults / this.resultsPerPage);
		},
		totalText() {
			let total = this.totalResults;
			let start = this.currentPage > 1
				? (this.currentPage - 1) * this.resultsPerPage
				: 1;
			let end = this.currentPage * this.resultsPerPage;
			end = end > total ? total : end;
			return `Showing ${start} - ${end} of ${total}`;
		}
	},
	data() {
		return {
			currentPage: 1,
			pages: [],
			numPages: 1
		}
	},
	methods: {
		updateResult(result) {

			// Get pagingation data
			let numPages = Math.ceil(this.totalResults / this.resultsPerPage);
			let start = 1, end = numPages;
			let pages = [];

			// Limit to show only 6 page numbers at a time.
			if(numPages > 6) {
				// Active page should be third number, unless it is within 2 pages of
				// the first or last page, then adjust accordingly
				start = this.currentPage - 2;
				end = this.currentPage + 3;
				if(start < 1) start = 1;
				if (end <= 6) end = (6 > numPages ? numPages : 6);
				if (end > numPages) end = numPages;
				if(start > end - 5) start = end - 5;
			}

			// Add the page numbers to an array for v-for loop
			for(let i = start; i <= end; i++) {
				pages.push(i);
			}

			this.pages = pages;
			this.result = result;
			this.numPages = numPages;
		},

		paginate(pageNum, noEmit = false) {
			if(pageNum < 1 || pageNum > Math.max.apply(Math, this.pages)) return;
			this.currentPage = pageNum;
			if(!noEmit) this.$emit('paginate', pageNum);
			this.updateResult();
		},
	},
	mounted() {
		this.updateResult();
	},
	watch: {
		totalResults() {
			this.updateResult();
		},
		pageNum(page) {
			if(page !== this.currentPage) this.currentPage = page;
			this.paginate(page, true);
		}
	}
}
</script>

<style scoped>
	.pagination-wrapper:after {
		content: "";
		clear: both;
		display: table;
	}
	.pagination-wrapper ul {
		margin-top: 0;
	}
	.pagination-wrapper .pagination li a {
		color: #00539F;
	}
	.pagination-wrapper .pagination > .active > a {
		border-color: #00539F;
		background-color: #00539F;
		color: #FFF;
		z-index: 0;
	}
	.pages {
		text-align: right;
		margin-bottom: 5px;
	}
</style>