<template>
	<div class="aged-inventory-order">
		<h2>Order Request</h2>
		<div class="order-items">
			<table class="table table-responsive table-condensed">
				<thead>
					<tr>
						<th>SKU</th>
						<th>Description</th>
						<th>Qty</th>
						<th style="width: 40px">UoM</th>
						<th style="width: 80px; text-align: right">Price</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr class="order-item" v-for="item in order.items" :key="item.itemID">
						<td>{{item.item.sku}}</td>
						<td>{{item.item.description}}</td>
						<td style="width: 40px">
							<input v-model.number="item.qty" style="width: 40px">
						</td>
						<td style="width: 40px">{{item.item.unitOfMeasurement}}</td>
						<td style="width: 80px; text-align: right">${{(item.item.salePrice * item.qty).toFixed(2)}}</td>
						<td>
							<i class="fa fas fa-times" @click="removeItem(item)"></i>
						</td>
					</tr>
				</tbody>
			</table>
			<div v-if="itemCount <= 0">No items have been added to your order yet.</div>
		</div>
		<div class="total float-right" v-if="itemCount > 0">
			<table class="table table-responsive table-condensed">
				<tbody>
					<tr>
						<td></td>
						<td></td>
						<td></td>
						<td style="width: 80px"></td>
						<td style="width: 160px; text-align: right">
							<b>Est. Product Total</b>
							<br>
							<span>{{total}}</span>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div style="clear: both"></div>
		<div class="submit" v-if="itemCount > 0">
			<div class="dark-btn float-right" @click="submit">Create Request</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'AgedInventoryOrder',
	computed: {
		itemCount() {
			return this.$store.getters['itemCount'];
		},
		total() {
			let total = 0;
			this.order.items.forEach(item => {
				total += item.qty * item.item.salePrice
			});
			return `$${total.toFixed(2)}`;
		},
		...mapState({
			order: state => state.order
		})
	},
	data() {
		return {

		}
	},
	methods: {
		submit() {
			this.$store.commit('orderModalVisible', true);
		},
		removeItem(item) {
			this.$store.dispatch('removeItem', item);
		}
	}
}
</script>

<style lang="less">
.aged-inventory-order {
	background-color: #fff;
	border: solid 2px #ccc;
	min-height: 300px;
	max-height: 1000px;
	padding: 20px;
	position: absolute;
	top: 30px;
	right: 0;
	width: 500px;
	z-index: 9;
}
.order-items {
	margin-bottom: 10px;
	max-height: 320px;
	overflow-y: scroll;
}
.order-items {
	td {
		font-size: 12px;
	}
	i.fa {
		cursor: pointer;
		font-size: 18px;
		&:hover {
			color: red;
		}
	}
}
</style>