<template>
	<div class="aged-inventory-filters" @keypress.enter="apply">
		<h2>Search Filters</h2>
		<div class="ai-inputs" v-if="searchRequest">
			<div class="form-group hide-mobile">
				<label for="keyword">Keyword</label>
				<input v-model="searchRequest.keyword" class="form-control" id="keyword" value="" />
			</div>
			<div class="form-group">
				<label for="itemCategory">Category</label>
				<select v-model="searchRequest.category" class="form-control" id="itemCategory">
					<option value="" selected>All</option>
					<option v-for="c in categories" :key="c" :value="c">{{c}}</option>
				</select>
			</div>
			<div class="form-group">
				<label for="itemMfr">Manufacturer</label>
				<select v-model="searchRequest.manufacturer" class="form-control" id="itemMfr">
					<option value="" selected>All</option>
					<option v-for="m in manufacturers" :key="m" :value="m">{{m}}</option>
				</select>
			</div>
			<div class="form-group hide-mobile">
				<label for="minQty" class="hide-tablet">Minimum Available Qty</label>
				<label for="minQty" class="show-tablet">Quantity</label>
				<input v-model.number="searchRequest.minQuantity" class="form-control" id="minQty" />
			</div>
			<div class="flex between show-mobile">
				<div class="form-group">
					<label for="keyword">Keyword</label>
					<input v-model="searchRequest.keyword" class="form-control" id="keyword" />
				</div>
				<div class="form-group">
					<label for="minQty" class="hide-tablet">Minimum Available Qty</label>
					<label for="minQty" class="show-tablet">Quantity</label>
					<input v-model.number="searchRequest.minQuantity" class="form-control" id="minQty" />
				</div>
			</div>
		</div>
		<div class="flex between block-tablet">
			<div class="aif-btns">
				<div class="flex between">
					<div class="blue-btn block filter-btn" @click="clear">Clear All</div>
					<div class="dark-btn block filter-btn" @click="apply">Apply Filters</div>
				</div>
			</div>
			<div class="aif-disclaimer">
				<p>Note: Prices limited to current inventory while supplies last. All products not available in all areas. All sales are final. No returns will be accepted.</p>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'AgedInventoryFilters',
	computed: {
		...mapState({
			categories: state => state.categories,
			manufacturers: state => state.manufacturers,
			searchRequest: state => state.searchRequest,
		})
	},
	data() {
		return {

		}
	},
	methods: {
		apply() {
			this.searchRequest.pageNum = 1;
			this.$store.dispatch('search');
		},
		clear() {
			this.searchRequest.pageNum = 1;
			this.searchRequest.category = "";
			this.searchRequest.keyword = "";
			this.searchRequest.minQuantity = 1;
			this.searchRequest.manufacturer = "";
			this.$store.dispatch('search');
		}
	}
}
</script>

<style lang="less">
@import "~Less/_mixins.less";
.aged-inventory-filters {
	background-color: #f7f7f7;
	border: solid 2px #DDD;
	border-radius: 4px;
	flex: 0 0 290px;
	padding: 12px;
	margin-right: 20px;
	max-height: 800px;
	margin-bottom: 20px;
	width: 100%;
	label {
		font-weight: bold;
		font-size: 13px;
		margin-bottom: 5px;
	}
	input, select {
		font-size: 13px;
	}
	.filter-btn {
		flex: 0 1 48%;
	}
	.blue-btn, .dark-btn {
		font-size: 12px;
	}
}

.ai-inputs {
	display: flex;
	justify-content: space-between;
	> div {
		margin-right: 15px;
		flex: 1 1 auto;
		&:nth-child(4) {
			margin-right: 0;
		}
	}
	.mobile-styles({
		display: block;
		> div {
			margin-right: 0;
		}
	});
}

.aif-btns {
	flex: 0 0 500px;
	order: 2;
}

.aif-disclaimer {
	flex: 0 1 700px;
	order: 1;
	padding-right: 10px;
	P {
		font-style: italic;
	}
	.tablet-styles({
		margin-top: 10px;
		padding-right: 0;
	})
}
</style>