<template>
	<div class="aged-inventory-item flex between">
		<div class="ai-img" @click="() => item.imagePath ? $emit('click:image', item) : ''">
			<img :src="item.imagePath" height="50" width="50" v-if="item.imagePath" />
		</div>
		<div class="ai-details">
			<div class="ai-name">{{item.manufacturer}} {{item.description}}</div>
			<div class="ai-cat">
				SKU: {{item.sku}} | Serial: {{item.serialNumber}}
			</div>
			<div class="ai-cat">
				Cat: {{item.productCategory}} <span v-if="item.shade && item.shade.length > 1">| Shade: {{item.shade}}</span>
			</div>
			<div class="ai-qty">
				Qty Available: {{item.qtyAvailable}}
			</div>
		</div>
		<div class="ai-price">
			<span class="was" v-if="item.listPrice">${{item.listPrice.toFixed(2)}}/{{item.unitOfMeasurement}}<br/></span>
			<span class="bold now">${{item.salePrice.toFixed(2)}}/{{item.unitOfMeasurement}}</span>
			<div class="ai-add">
				<div v-if="!itemAdded">
					<div class="blue-btn add-btn" @click="showModal = true">Add To Order</div>
				</div>
				<div v-else>
					<div class="added"><i class="fa fas fa-check"></i> Added</div>
				</div>
			</div>
		</div>
		<div class="ai-qty-modal flex center-all" v-if="showModal">
			<div class="aiq-inner">
				<h2>Enter Desired Quantity</h2>
				<p>Qty Available: {{item.qtyAvailable}}</p>
				<div>
					<input v-model.number="qty" :class="{'qty-error': qtyError}">
				</div>
				<div class="submit flex flex-between">
					<div class="blue-btn" @click="showModal = false">Cancel</div>
					<div class="blue-med-btn" @click="addToOrder">Submit</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'AgedInventoryItem',
	props: {
		order: {
			type: Object,
			required: true
		},
		item: {
			type: Object,
			required: true
		}
	},
	computed: {
		itemAdded() {
			if(!this.order || !this.order.items) return false;
			return this.order.items.some(x => x.itemID == this.item.id);
		},
	},
	data() {
		return {
			qty: 0,
			qtyError: false,
			showModal: false
		}
	},
	methods: {
		addToOrder() {
			if(!this.qty || this.qty > this.item.qtyAvailable) {
				this.qtyError = true;
				return;
			}
			this.qtyError = false;
			this.$emit('click:add', {
				itemID: this.item.id,
				qty: this.qty,
				orderID: this.order.id
			});
		}
	}
}
</script>


<style lang="less">
	@import "~Less/_variables.less";
	.aged-inventory-item {
		border-bottom: 2px solid #DDD;
		padding-bottom: 10px;
		margin-bottom: 20px;
		.added {
			margin-top: 10px;
			text-align: right;
			width: auto;
		}
	}
	.ai-img {
		flex: 0 0 50px;
		margin-right: 10px;
	}
	.ai-details {
		flex: 1 1 auto;
		> div {
			margin: 0 0 5px;
		}
	}
	.ai-cat {
		color: #AAA;
		font-size: 12px;
	}
	.ai-qty {
		font-weight: bold;
		font-size: 12px;
	}
	.ai-price {
		text-align: right;
		flex: 0 0 110px;
		font-size: 12px;
		.now {
			font-size: 14px;
			font-weight: bold;
		}
	}
	.add-btn {
		font-size: 12px;
		margin-top: 5px;
		padding: 8px;
	}
</style>