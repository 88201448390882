<template>
	<div class="aged-inventory-order-modal flex center-all">
		<div class="ai-modal">
			<div class="ai-inner">
				<div class="flex-row between order-modal-header">
					<h2>Order Request</h2>
					<div class="close-modal-btn" @click="$emit('cancel')">
						<i class="fa fa-times"></i>
					</div>
				</div>
				<div class="order-items">
					<table class="table table-responsive">
						<thead>
							<tr>
								<th class="hide-mobile">SKU</th>
								<th>Description</th>
								<th>Qty</th>
								<th>UoM</th>
								<th style="width: 80px; text-align: right">Price</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr class="order-item" v-for="item in order.items" :key="item.itemID">
								<td class="hide-mobile">{{item.item.sku}}</td>
								<td>{{item.item.description}}</td>
								<td style="width: 40px">
									<input v-model.number="item.qty" style="width: 40px">
								</td>
								<td style="width: 40px">{{item.item.unitOfMeasurement}}</td>
								<td style="width: 80px; text-align: right">${{(item.item.salePrice * item.qty).toFixed(2)}}</td>
								<td>
									<i class="fa fas fa-trash-o" @click="removeItem(item)"></i>
								</td>
							</tr>
						</tbody>
					</table>
					<div v-if="itemCount <= 0">No items have been added to your order yet.</div>
				</div>
				<div class="total float-right">
					<table class="table table-responsive table-condensed">
						<tbody>
							<tr>
								<td></td>
								<td></td>
								<td></td>
								<td style="width: 80px"></td>
								<td style="width: 120px">
									<b>Est. Total</b>
									<br>
									<span>{{total}}</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div style="clear: both"></div>
				<div class="ai-form">
					<div class="row">
						<div class="col-md-4">
							<div class="form-group">
								<label>
									Name
									<span class="required">(required)</span>
								</label>
								<input class="form-control" v-model="order.name" type="text">
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group">
								<label>
									Email Address
									<span class="required">(required)</span>
								</label>
								<input class="form-control" v-model="order.email" type="text">
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group">
								<label>Phone</label>
								<input class="form-control" v-model="order.phone" type="text">
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-4">
							<div class="form-group">
								<label>
									Account Number
									<span class="required">(required)</span>
								</label>
								<input class="form-control" v-model="order.accountNumber" type="text">
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group">
								<label>
									Company
									<span class="required">(required)</span>
								</label>
								<input class="form-control" v-model="order.company" type="text">
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group">
								<label>Sidemark</label>
								<input class="form-control" v-model="order.sidemark" type="text">
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<div class="form-group text-center">
								<label>
									<input class="form-conrol" type="checkbox" v-model="order.mustShipComplete" />
									Must Ship Complete
								</label>
							</div>
						</div>
					</div>
				</div>
				<div class="error" v-if="error">
					<i class="fa fas fa-exclamation-triangle"></i>
					{{error}}
				</div>
			</div>
			<div class="submit flex flex-between">
				<div class="blue-btn" @click="$emit('cancel')">
					<i class="fa fa-times"></i> Cancel
				</div>
				<div class="blue-med-btn" @click="submit">
					Submit
					<span class="hide-mobile">Request</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'AgedInventoryOrderModal',
	computed: {
		itemCount() {
			return this.$store.getters['itemCount'];
		},
		total() {
			let total = 0;
			this.order.items.forEach(item => {
				total += item.qty * item.item.salePrice
			});
			return `$${total.toFixed(2)}`;
		},
		...mapState({
			order: state => state.order
		})
	},
	data() {
		return {
			error: null
		}
	},
	methods: {
		async submit() {
			if(!this.validate()) return;
			this.$emit('cancel');
			let success = await this.$store.dispatch('submitOrder');
			if(success) window.location = '/thank-you?form=sale';
		},
		removeItem(item) {
			this.$store.dispatch('removeItem', item);
		},
		validate() {
			this.error = null;
			let o = this.order;
			if(!o.name) {
				this.error = "Name is required";
			}
			else if(!o.accountNumber) {
				this.error = "Account number is required";
			}
			else if(!o.email) {
				this.error = "Email address is required";
			}
			else if(!o.company) {
				this.error = "Company is required";
			}
			else {
				o.items.forEach(oi => {
					if(isNaN(oi.qty)) {
						this.error = `Please enter a valid quantity for ${oi.item.sku}`;
					}
					else if(oi.qty <= 0) {
						this.error = `A quantity of 1 or more is required for ${oi.item.sku}`;
					}
					else if(oi.qty > oi.item.qtyAvailable) {
						this.error = `${oi.item.sku} has only ${oi.item.qtyAvailable} units availble`;
					}
				});
			}
			return !this.error;
		}
	},
	mounted() {
		document.body.classList.add('modal-open');
	},
	beforeDestroy() {
		document.body.classList.remove('modal-open');
	}
}
</script>

<style lang="less">
@import "~Less/_mixins.less";
.aged-inventory-order-modal {
	background-color: rgba(0, 0, 0, 0.2);
	flex-direction: column;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 5 !important;
}
.ai-modal {
	background-color: #fff;
	border-radius: 9px;
	box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.3);
	overflow-y: scroll;
	max-width: 800px;
	width: 100%;
	.ai-inner {
		padding: 25px;
	}
	.submit {
		background: #eee;
		text-align: center;
		padding: 25px;
		> div {
			align-items: center;
			flex: 1 1 auto;
			margin: 0 8px 0 0;
			padding: 12px 20px;
			width: 200px;
			&.blue-btn {
				margin: 0 8px 0;
				padding: 10px 20px;
			}
		}
		.mobile-styles({padding: 15px;});
	}
	.error {
		border: solid 1px rgb(214, 3, 3);
		background-color: rgb(255, 238, 238);
		color: rgb(214, 3, 3);
		padding: 10px;
	}
	.required {
		color: #888;
		font-size: 12px;
	}
	.order-items {
		max-height: 350px;
		overflow-y: scroll;
	}
}
.order-modal-header {
	margin-bottom: 20px;
	h2 {
		margin-bottom: 0;
	}
}
.close-modal-btn {
	font-size: 24px;
}
</style>